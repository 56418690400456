import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Section  from "../layout/section"
import { FixedContainer } from "../layout/container"
import ExternalLink from "../layout/externalLink"


const CourseListing = ({ courses }) => {
  return (
    <Section>
      <FixedContainer>
        {courses.map(course => (
          <Course key={course.code} course={course} />
        ))}
      </FixedContainer>
    </Section>
  )
}

const Course = ({ course }) => (
  <div className="course">
    <h4 className="title is-4">{course.title}</h4>
    <h6 className="subtitle is-6">{course.code}</h6>
    <div dangerouslySetInnerHTML={{ __html: course.html }} />
    {course.prereqs && (
      <p>
        <strong>Prerequisites:</strong>
        {` `}
        <span>{course.prereqs.join(", ")}</span>
      </p>
    )}
    <p>
      <strong>Last Offered:</strong>
      {` `}
      {course.lastOffered}
      {course.syllabus && (
        <span className="links">
          {` `}
          <ExternalLink path={course.syllabus} title="Syllabus">
            <span className="icon is-small inline">
              <FontAwesomeIcon icon={["far", "file-pdf"]} title="PDF" />
            </span>
            <span>
              Syllabus
            </span>
          </ExternalLink>
        </span>
      )}
    </p>
    <p>
      <strong>Next Offering:</strong>
      {` `}
      {course.nextOffered}
    </p>
    {course.credits && (
      <p>
        <strong>Credits:</strong>
        {` `}
        {course.credits}
      </p>
    )}
  </div>
)

export default CourseListing
