import React from "react"
import { graphql } from "gatsby"


import ChildPage from "../components/layout/page"
import CourseList from "../components/education/course"

const CoursePage = ({ data }) => {

  const courseEdges = data.allMarkdownRemark.edges

  const headerImage = data.header.publicURL

  const parallax = {
    image: headerImage,
    strength: 400
  }

  const courseList =  []
  courseEdges.forEach(courseEdge => {
    courseList.push({
      title: courseEdge.node.frontmatter.title,
      code: courseEdge.node.frontmatter.code,
      lastOffered: courseEdge.node.frontmatter.lastOffered,
      nextOffered: courseEdge.node.frontmatter.nextOffered,
      credits: courseEdge.node.frontmatter.credits,
      prereqs: courseEdge.node.frontmatter.prereqs,
      syllabus: courseEdge.node.frontmatter.syllabus,
      html: courseEdge.node.html
    })
  })

  const breadcrumbs = [
    { "key": "home", "title": "Home", "path": "/" },
    { "key": "education", "title": "Education", "path": "/education" }
  ]

  return (
    <ChildPage
      title="Education"
      subtitle="Courses offered"
      breadcrumbs={breadcrumbs}
      parallax={parallax}
    >
      <CourseList courses={courseList} />
    </ChildPage>
  )
}

export default CoursePage

export const pageQuery = graphql`
  query CoursePage {
    header: file(relativePath: { eq: "aurora.jpg" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allMarkdownRemark(
      filter: { fields: { type: {eq: "course"} }}
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            code
            prereqs
            syllabus
            lastOffered
            nextOffered
            credits
          }
          fields {
            slug
            type
          }
        }
      }
    }
  }
`
